<template>
    <div class="content">
        <div>
            <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
            <el-button type="primary" class="el-icon-check" @click="SaveClick"> 保存</el-button>
            <el-button type="primary" class="el-icon-bottom" @click="Send"> 全部下发</el-button>
        </div>
        <el-table :data='$store.state.CardDataList' :key="tableKey" border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="card_name" label="卡类名称">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.card_name" v-if='scope.row.card_status===1'></el-input>
                    <span v-else>{{scope.row.card_name}}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="card_limit" label="类型">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.card_limit" v-if="scope.row.card_status===1" style="width:100%;">
                        <el-option label="月卡" :value='0'></el-option>
                        <el-option label="储值卡" :value='6'></el-option>
                        <el-option label="贵宾车" :value='5'></el-option>
                        <el-option label="临时车" :value='7'></el-option>
                        <el-option label="新能源临时车" :value='9'></el-option>
                    </el-select>
                    <span v-else>
                        {{scope.row.card_limit===0?'月卡':(scope.row.card_limit===6? '储值卡' :(scope.row.card_limit===5?'贵宾车':(scope.row.card_limit===7?'临时车':(scope.row.card_limit===9?'新能源临时车':'')) ))}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="card_month_charge" label="月租费用(元)">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.card_month_charge" v-if='scope.row.card_status===1' controls-position="right" :min="0" style="width:100%;"></el-input-number>
                    <span v-else>{{scope.row.card_month_charge}}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button v-if='scope.row.card_id!==1'  @click="EditClick(scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
                    <el-button v-if='scope.row.card_id!==1' @click="DelClick(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import util from '../../util/util'
import {mapGetters} from 'vuex'

export default {
  data: function () {
    return {
      CardDataList:[],
      tableKey: Math.random(),
      isFinish: true
    }
  },
  computed:{
    ...mapGetters(['filterCardDataList'])
  },
  created () {
    if(this.$store.state.CardDataList.length===0){
        this.$store.dispatch('getCardDataList')
    }
  },
  methods: {
    AddClick () {
      if(util.CheckUserAuth('2-1-1')){
        var form = {
          card_id: 0,
          card_name: '',
          card_limit: 0,
          card_month_charge: 0.00,
          card_status: 1
        }
        this.filterCardDataList(null,true).push(form)
      }
    },
    EditClick (row) {
      if(util.CheckUserAuth('2-1-2')){
        this.filterCardDataList(null,true).forEach(a => {
          if (a.card_id === row.card_id) {
            a.card_status = 1
          }
        })
        this.tableKey = Math.random()
      }
    },
    SaveClick () {
      var arr =  this.filterCardDataList(null,true).filter(a => a.card_name !== '')
      if (arr.length > 0) {
        util.Post('card/edit', arr).then(res => {
          if (res.rpStatus === 10000) {
            this.$message({ message: '保存成功', type: 'success' })
            this.$store.dispatch('getCardDataList') 
          }
        })
      }
    },
    DelClick (row) {
      if(util.CheckUserAuth('2-1-3')){
        this.$confirm('确定删除卡类“' + row.card_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('card/del?id=' + row.card_id+'&name='+row.card_name).then(res => {
            if (res.rpStatus === 10000) {
              this.$message({ message: '删除卡类成功', type: 'success' })
              this.$store.dispatch('getCardDataList') 
            }
          })
        }).catch(e => e)
      }
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('card').then(res=>{
          this.isFinish=true
        })
      }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
</style>
